<template>
  <v-select
    v-model="selectedLocation"
    outlined
    hide-details="auto"
    :items="availableLocations"
    :label="$trans('select_service_location_label')"
    :disabled="disabled"
    :rules="rules"
    class="cd-fake-outer"
    :attach="'#' + uniqueId"
    :menu-props="{ offsetY: true }"
    small-chips
    deletable-chips
    clearable
    @change="onChange"
  >
    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-select>
</template>
<script>
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import { getAvailableLocationFilters } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  mixins: [sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocation: this.value,
      availableLocations: getAvailableLocationFilters(),
    };
  },
  watch: {
    value(val) {
      this.selectedLocation = val;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.selectedLocation);
    },
  },
};
</script>
