import { mapActions } from "vuex";
import planActions from "@/calendesk/mixins/planActions";
import sharedListActions from "@/calendesk/mixins/sharedListActions";
import roleActions from "@/calendesk/mixins/roleActions";
import serviceLocationActions from "@/calendesk/mixins/serviceLocationActions";
import {
  getAvailableLocationFilters,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  mixins: [sharedListActions, planActions, roleActions, serviceLocationActions],
  data() {
    return {
      isLoading: false,
      availableLocations: getAvailableLocationFilters(),
    };
  },
  methods: {
    ...mapActions({
      deleteLocation: "location/deleteLocation",
      createLocation: "location/createLocation",
      updateLocation: "location/updateLocation",
      refreshLocations: "location/refreshLocations",
      refreshCategoriesAndServices: "category/fetchWithServices",
    }),
    handleAddLocation(hideServiceSelection) {
      if (this.canUseServiceLocations) {
        this.openDialog({
          type: dialogTypes.MANAGE_LOCATION,
          size: dialogSize.SMALL,
          title: this.$trans("add_location_title"),
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
          data: {
            hideServiceSelection,
          },
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleEditLocation(location) {
      if (this.canUseServiceLocations) {
        this.openDialog({
          type: dialogTypes.MANAGE_LOCATION,
          size: dialogSize.SMALL,
          title: this.$trans("update_location_title"),
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
          data: location,
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleDeleteLocations(locations) {
      if (this.canUseServiceLocations) {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          confirmAction: () => {
            pushEvent("removeLocation");

            this.setCommonDialogLoader(true);
            const requests = [];

            if (Array.isArray(locations)) {
              locations.forEach((location) => {
                requests.push(this.deleteLocation(location.id));
              });
            } else {
              requests.push(this.deleteLocation(location.id));
            }

            Promise.all(requests)
              .catch((error) => {
                errorNotification(null, error);
              })
              .finally(() => {
                this.refreshLocations();
                this.clearCache("categories-services-list");
                this.refreshCategoriesAndServices();
                this.setCommonDialogLoader(false);
                this.closeConfirmDialog();
              });
          },
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
  },
};
