export default {
  methods: {
    isStaticRuleLocation(locationType) {
      return (
        locationType === this.$helpers.staticRuleLocationTypes.ONLINE ||
        locationType === this.$helpers.staticRuleLocationTypes.PHONE
      );
    },
    getIconForType(type) {
      switch (type) {
        case this.$helpers.serviceLocationTypes.IN_PERSON:
        case this.$helpers.serviceLocationTypes.OTHER:
          return "$marker";
        case this.$helpers.serviceLocationTypes.PHONE:
        case this.$helpers.staticRuleLocationTypes.PHONE:
          return "$phone";
        case this.$helpers.staticRuleLocationTypes.ONLINE:
          return "$video-box";
        default:
          return null;
      }
    },
    getImageForType(type) {
      switch (type) {
        case this.$helpers.serviceLocationTypes.GOOGLE_MEET:
          return require("@/assets/google-meet-small-logo.png");
        case this.$helpers.serviceLocationTypes.ZOOM:
          return require("@/assets/zoom-logo-square.png");
        case this.$helpers.serviceLocationTypes.WHATSAPP:
          return require("@/assets/whatsapp-logo-small.png");
        case this.$helpers.serviceLocationTypes.TEAMS:
          return require("@/assets/teams-logo-small.png");
        case this.$helpers.serviceLocationTypes.SKYPE:
          return require("@/assets/skype-logo-small.png");
        default:
          return null;
      }
    },
  },
};
