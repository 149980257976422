<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container fluid style="min-height: 450px">
      <v-row>
        <v-col cols="12">
          <location-type-field
            v-model="type"
            :disabled="isLoading || !!locationId"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row v-if="typeHasName">
        <v-col cols="12">
          <v-text-field
            v-model="name"
            hide-details="auto"
            :rules="[rules.maxChars(255)]"
            :label="labelForName"
            outlined
            :disabled="isLoading"
            @keypress.enter.prevent="handleSave"
          />
        </v-col>
      </v-row>
      <v-row v-if="typeHasData">
        <v-col cols="12">
          <v-text-field
            v-model="data"
            hide-details="auto"
            :rules="[rules.maxChars(255)]"
            :label="labelForType"
            outlined
            :disabled="isLoading"
            @keypress.enter.prevent="handleSave"
          />
        </v-col>
      </v-row>
      <v-row v-if="!hideServiceSelection">
        <v-col cols="12">
          <services-field
            v-model="serviceIds"
            :label="$trans('select_services_title')"
            multiple
            small-chips
            deletable-chips
            return-ids
            :disabled="isLoading"
            keep-menu-open-on-select
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import locationActions from "@/calendesk/mixins/locationActions";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";
import LocationTypeField from "@/components/Forms/LocationTypeField.vue";

export default {
  name: "ManageLocationDialog",
  components: { LocationTypeField, ServicesField },
  mixins: [locationActions],
  data() {
    return {
      locationId: null,
      type: null,
      data: null,
      name: null,
      serviceIds: [],
      rules: {
        required,
        maxChars,
      },
      hideServiceSelection: false,
    };
  },
  computed: {
    typeHasData() {
      return (
        this.type === this.$helpers.serviceLocationTypes.IN_PERSON ||
        this.type === this.$helpers.serviceLocationTypes.PHONE ||
        this.type === this.$helpers.serviceLocationTypes.OTHER
      );
    },
    typeHasName() {
      return (
        this.type === this.$helpers.serviceLocationTypes.IN_PERSON ||
        this.type === this.$helpers.serviceLocationTypes.PHONE ||
        this.type === this.$helpers.serviceLocationTypes.OTHER
      );
    },
    labelForType() {
      switch (this.type) {
        case this.$helpers.serviceLocationTypes.IN_PERSON:
          return this.$trans("service_location_1_data_label");
        case this.$helpers.serviceLocationTypes.PHONE:
          return this.$trans("service_location_2_data_label");
        case this.$helpers.serviceLocationTypes.OTHER:
          return this.$trans("service_location_3_data_label");
        default:
          return null;
      }
    },
    labelForName() {
      return this.$trans("service_location_name");
    },
  },
  created() {
    if (this.getDialog && this.getDialog.data) {
      if (this.getDialog.data.id && this.getDialog.data.type) {
        this.locationId = this.getDialog.data.id;
        this.type = this.getDialog.data.type;
        this.data = this.getDialog.data.data ?? null;
        this.name = this.getDialog.data.name ?? null;
        this.serviceIds = this.getDialog.data.service_ids ?? [];
      }

      if (this.getDialog.data.hideServiceSelection) {
        this.hideServiceSelection = this.getDialog.data.hideServiceSelection;
      }
    }
  },
  methods: {
    handleSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.$emit("ctaButtonLoading", true);

        const action = this.locationId
          ? this.updateLocation
          : this.createLocation;

        const data = {
          type: this.type,
          data: this.data ?? null,
          name: this.name ?? null,
          service_ids: this.serviceIds ?? [],
        };

        if (this.locationId) {
          data.location_id = this.locationId;
        }

        action(data)
          .then((location) => {
            this.refreshLocations();
            this.clearCache("categories-services-list");
            this.refreshCategoriesAndServices();
            this.$emit("close", {
              location,
            });

            if (this.locationId) {
              successNotification("updated_location_notification");
            } else {
              successNotification("added_location_notification");
            }
          })
          .catch((error) => {
            errorNotification(null, error);
          })
          .finally(() => {
            this.$emit("ctaButtonLoading", false);
            this.isLoading = false;
          });
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
  },
};
</script>
